.countdown {
    font-size:6vw;
    width: 20vw;
    font-family: swisCnBt;
    font-weight: bold;;
    color: #032436;
}

.countdownOutput {
    display: flex;
    text-align: center;
}

.countdownOutput div {
    flex-direction: row;
}

.datePassedMessage {
    font-size: 30px;
}

.countdownOutput .timeUnit {
    font-family: agency;
    font-size: 4vw;
    color: lightgrey;
}

.countdownOutput p {
    margin: 0;
}

@media (orientation: portrait) {
    #homeCountdownContainer .countdown {
        font-size: 6vh;
        width: auto;
        text-align: center;
    }

    #homeCountdownContainer .countdownOutput {
        flex-direction: column;
    }
    
    .timeUnit {
        text-align: center;
    }

    .countdownOutput div p {
        display: block;
    }
    
    #homeCountdownContainer .countdownOutput div {
        margin: 2.5vh 0;
    }

    #upcomingSeminarTopContainer #seminarCountdown .countdown {
        font-size: 6vw;
    }

    #upcomingSeminarTopContainer #seminarCountdown .timeUnit {
        font-size: 4vw;
    }

}