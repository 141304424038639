#navLinksList {
    display: flex;
    list-style: none;
    padding: 0;
    width: 100%;
    /* line-height:2vh; */
    padding-top: 1.2vw;
    margin: 0;
}

.navElements {
    flex-direction: row;
    width: 100%;
    text-align: center;
}

.navLink {
    font-size: 1.25vw;
    text-decoration: none;
    color: white;
}

.navLink:hover {
    color: purple;
}

#menuButton {
    width: 5vw;
    height: 5vw;
    margin-right: 15px;
    margin-top: 4vw;
    /* width: 20px;
    height: 20px; */
    float: right;
}

.change .bar1 {
    transform: rotate(-45deg) translateY(2.75vw);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: rotate(45deg) translateY(-2.75vw);
}

.bar1, .bar2, .bar3 {
    width: 100%;
    height: 1vw;
    background-color: white;
    margin: 1vw 0;
    transition: 0.4s;
}

#navigationBarContainer {
    width: 60%;
    float: right;
}

@media (orientation:portrait) {
    #navigationBarContainer {
        width: 0;
    }
    #menuButton {
        position: absolute;
        right: 0;
        z-index: 5;
    }
    #navLinksList {
        width: 30vw;
        background-color: purple;
        opacity: .9;
        height: 100vh;
        display: block;
        /* animation-name: closeMenu;
        animation-duration: 1s;
        animation-fill-mode: forwards; */
        padding-top: 8vh;
    }
    .navElements {
        flex-direction: column;
        margin-bottom: 7vw;
    }
    .navLink {
        font-size: 5vw;
    }
    .navLink:hover {
        color: white;
    }
    .navLink.activeView {
        text-decoration: underline;
    }
    #navLinksList.openMenu {
        animation-name: openMenu;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }
    #navLinksList.closeMenu {
        animation-name: closeMenu;
        animation-duration: .5s;
        animation-fill-mode: forwards;
    }
    @keyframes openMenu {
        from {
            margin-left: 0;
        }
        to {
            margin-left: -30vw;
        }
    }
    @keyframes closeMenu {
        from {
            margin-left: -30vw;
        }
        to {
            margin-left: 0;
        }
    }
}