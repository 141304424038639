html {
  overflow-y: scroll;
}

#body {
  margin:auto;
  padding: 0 10%;
  padding-top: 6vw;
  overflow-x: hidden;
}

.container {
  margin: auto;
  min-height: 75vh;
}

@font-face {
  font-family: swisBlkcnBt;
  src: url('../Res/fonts/swis721-blkcn-bt-black.ttf');
}

@font-face {
  font-family: swisCnBt;
  src: url('../Res/fonts/swis721-cn-bt-roman.ttf')
}

@font-face {
  font-family: swisLtcnBt;
  src: url('../Res/fonts/swis721-ltcn-bt-light.ttf')
}

@font-face {
  font-family: agency;
  src: url('../Res/fonts/agencyfb-regular.otf')
}

#body {
  padding-top: 10vh;
}

.pageHeading {
  font-family: swisBlkcnBt;
  margin-bottom: 0;
  font-size: 2.5vw;
  color: purple;
} 

.pageHeadingSubtext {
  font-family: swisLtcnBt;
  font-size: 1.05vw;
  margin-top: 0;
  width: 50%;
}

.form {
  margin: auto;
  width: 100%;
  max-width: 750px;
  height: 195vh;
}

.video {
  width: 68vw;
  height: 34vw;

  /* width: 320px;
  height: 179px; */
  margin: 2vh auto;

}

@media (orientation: portrait) {
  .pageHeading {
      font-size: 5vh;
  }

  .pageHeadingSubtext {
      font-size: 2.5vh;
      width: 90%;
    }

  .form {
    width: 120%;
    margin-left: -10%;
    height: 100vh;

  }
}