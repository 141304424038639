.socialMediaLogo {
    width: 35px;
    padding: 7px;
    margin-top: 10px;
}

footer {
    width: 100%;
    text-align: center;
    background-color: white;
}

footer p {
    margin:0;
}

@media (orientation: portrait) {
    .socialMediaLogo {
        width: 20px;
    }

    footer p {
        font-size: 10px;
    }
}