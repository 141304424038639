.countdownHeading {
    text-align: center;
}

#homeImage {
    width: 120vw;
    margin-top: -6vw;
    opacity: .8;
    margin-left: -15%;
}

#countdownUntilExamDeadline  {
    color: purple;
}

#countdownUntilSeminar  {
    color: lightgrey;
    text-shadow: -.2vw .2vw .5vw black;
}

.countdownItem {
    color: purple;
    text-shadow: -.2vw .2vw .5vw black;
}

.countdownHeading p {
    margin: 0;
    padding: 0;
    font-size: 6vw;
    font-family: agency;
}

.countdownContainer {
    position: absolute;
    top: 17vw;
    width: 80%;
    margin: auto;
}

@media (orientation: portrait) {
    #homeImage {
        height: 90vh;
        width: 175vh;
        margin-left: -25vh;
        margin-top: -10vh;
    }

    .countdownHeading p {
        font-size: 34px;
    }

    .countdownContainer {
        position: absolute;
        top: 14vh;
        margin: auto;
     
    }
    
}