header {
    width: 100%;
    height: 4vw;
    background-color: #032436;
    display: flex;
    position: fixed;
    z-index: 2;
    font-family: swisCnBt;
    opacity: .8;
}

#headerLogoLink {
    width:35%;
    margin-right:5%;
    text-decoration: none;
}

#headerLogo {
    padding:0;
    margin:0;
    text-align: center;
    color: white;
}

.headerLogoText {
    margin: 0;
    padding: 0;
    /* border: 1px solid white; */
}

#qabeelatDurbahText {
    font-size: 2.1vw;
}

#qabeelatDurbahSubtext {
    font-size: .87vw;
    font-weight: lighter;
}

@media (orientation: portrait) {

    #headerLogoLink {
        width: 100%;
        margin:auto;
    }

    header {
        text-align: center;
        height: 8vh;
        width: 100%;
        margin: auto;
    }

    #qabeelatDurbahText {
        font-size: 5.5vw;
    }
    
    #qabeelatDurbahSubtext {
        font-size: 3vw;
        font-weight: lighter;
    }    

}