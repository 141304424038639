#upcomingSeminarBottomContainer {
    display: flex;
    margin-top: 5vw;
}

#seminarFlyer {
    width: 100%;
}

.upcomingSeminarContent {
    flex-direction: row;
    width: 33.3%;
    padding: 2vw;
    margin: 0;
}

#upcomingSeminarContainer {
    font-family: 'agency';
    font-size: 20px;
}

#upcomingSeminarLeft {
    text-align: center;
}

#seminarCountdown .countdown {
    font-size: 4vw;
}

#seminarCountdown .countdownOutput .timeUnit {
    font-size: 1.5vw;
}

#seminarName{
    margin: 0;
    padding: 0;
}

#seminarInstructor{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

#aboutInstructor {
    width:80%;
    font-size: 20px;
    float: right;
}

.upcomingSeminarButton {
    margin-top: 2vw;
    width: 100px;
    background-color: purple;
    color: white;
    text-decoration: none;
    text-align: center;
    float: right;
}

#instructorImage {
    width: 60%;
    position: relative;
    margin-left: 20px;
    border-radius: 150px;
    border: 1px solid purple;
}

#upcomingSeminarTopContainer {
    text-align: center;
    border-top: 0;
    height: 90vh;
}

#seminarDescription {
    font-family: swisCnBt;
    text-align: justify;
    padding-top: 30px;
}

#instructorDescription {
    font-family: swisCnBt;
    text-align: justify;
    padding-top: 30px;
}

#upcomingSeminarRight  {
    text-align: right;
}

#instructorName {
    font-size: 20px;
}

@media (orientation: portrait) {
    #upcomingSeminarTopContainer {
        height: fit-content;
    }

    #upcomingSeminarBottomContainer {
        flex-direction: column;
    }

    #seminarName {
        font-size: 9vw;
    }

    #seminarInstructor {
        font-size: 7vw;
    }

    .upcomingSeminarContent {
        width: 100%;
        border-top: 1px solid black;
        margin-top: 20px;
        padding-top: 20px;
    }

    .seminarDescriptions {
        font-size: 5vw;
    }

    #seminarFlyer {
        width: 75%;
    }
}